import React from 'react';
import Link from 'components/link';
import Button from 'components/control/button';
import MapIcon from 'components/icon/map-icon';
import { trackEvent } from 'utils/google-tag-manager';
import styles from './style.module.scss';
import { useUserContext, usePreferencesContext } from 'contexts';
import { homepageIds } from 'constants/test-constants';
import { GTM_CLICK_HOME_PAGE_LINKED_TEXT_BUTTON,
  GTM_CLICK_HOME_PAGE_FOR_SALE_VIEW_MAP_BUTTON } from 'constants/events';
import replaceFrenchAccents from 'utils/replace-french-accents';
import dynamic from 'next/dynamic';
import { useIsMobile, useIsTablet } from 'hooks/use-size-class';
import { capitalizeWords } from '@zoocasa/node-kit/strings';

import type Listing from 'data/listing';
import type { ListingCardData } from 'components/listing-card';

const ListingCardCarousel = dynamic(() => import('components/listing-card-carousel'));

type ZoocasaForSaleSectionProps = {
  isCrawler: boolean;
  availableHomeListings: ListingCardData[];
  availableHomeListingsLocation: string;
  isExpandedArea?: boolean;
  homesForSaleUrl: string;
  mapViewUrl: string;
  availableHomeListingsMeta: number;
}

export default function ZoocasaForSaleSection({ isCrawler, availableHomeListings, availableHomeListingsLocation, isExpandedArea, homesForSaleUrl, mapViewUrl, availableHomeListingsMeta }: ZoocasaForSaleSectionProps) {
  const { userLocation } = useUserContext();
  const { listingParams, lastSearchLocation } = usePreferencesContext();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  const setMapSlug = () => {
    listingParams.setAreaName(lastSearchLocation?.description || userLocation?.name || '');
  };

  return (
    <section className={styles.component}>
      <div className={styles['home-block']} data-testid={homepageIds.homesForSaleWidget}>
        <div className={styles.header}>
          <div className={styles.left} data-testid={homepageIds.homesForSaleTitle}>
            <p className={styles.properties}>{availableHomeListingsMeta}+ Properties{!isMobile && ' For Sale'} {isExpandedArea ? 'near' : 'in'}</p>
            <Link onClick={() => trackEvent(GTM_CLICK_HOME_PAGE_LINKED_TEXT_BUTTON)} href={replaceFrenchAccents(homesForSaleUrl)}><h2 className={styles.location} data-testid={homepageIds.homesForSaleLocation}>{capitalizeWords(availableHomeListingsLocation)}</h2></Link>
          </div>
          {!isTablet &&
          <Link href={mapViewUrl}>
            <Button label="Map View" Icon={MapIcon} theme="secondary" testId={homepageIds.homesForSaleMapViewButton}
              onClick={()=>{
                trackEvent(GTM_CLICK_HOME_PAGE_FOR_SALE_VIEW_MAP_BUTTON);
                setMapSlug();}} />
          </Link>
          }
        </div>
        <ListingCardCarousel listingCardsData={{ listings: availableHomeListings as Listing[], type: 'available', userLocation, redirectUrl: replaceFrenchAccents(homesForSaleUrl), isCTAIncluded: true }} isCrawler={isCrawler}/>
        {isTablet &&
          <div className={styles['mobile-view-map']}>
            <Link href={mapViewUrl}>
              <Button label="Map View" Icon={MapIcon} theme="secondary" testId={homepageIds.homesForSaleMapViewButton}
                onClick={()=>{
                  trackEvent(GTM_CLICK_HOME_PAGE_FOR_SALE_VIEW_MAP_BUTTON);
                  setMapSlug();}} />
            </Link>
          </div>
        }
      </div>
    </section>
  );
}
